// ---------------------------------------------------------===
// Typography
// ---------------------------------------------------------===

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
  font-family: $headings-font-family;
  font-weight: $headings-weight;
  margin: 0 0 0.5em;
  color: $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: 1.19;

  @include media('>=desktop') {
    @include font(78px, 1.18);
  }
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: 1.36;

  @include media('>=desktop') {
    @include font(40px, 1.48);
  }
}

h3, .h3 {
  font-size: $h3-font-size;
}

h4, .h4 {
  font-size: $h4-font-size;
}

h5, .h5 {
  font-size: $h5-font-size;
}

h6, .h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 1em;
}

a {
  color: $base-link-color;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}