// ---------------------------------------------------------===
// Variables
// ---------------------------------------------------------===

// Colors
$gray: #999;
$black: #000;
$white: #fff;
$light-blue: #39f;
$mine-shaft: #2c2c2c;
$alabaster: #f9f9f9;
$dodger-blue: #4459fb;

$roboto: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
$base-font-sans-serif: $roboto;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// Body
$base-text-color: $mine-shaft !default;
$base-background-color: $alabaster !default;
$font-size-base: 16px !default;
$line-height-base: 1.63 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $base-text-color;

// Buttons 
$btn-color: $white !default;
$btn-background: $dodger-blue !default;
$btn-padding: 1.1em 1.3em !default;
$btn-border-radius: 4px !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: #d9d9d9 !default;
$form-element-focus-border-color: $dodger-blue !default;
$form-border-radius: $btn-border-radius !default;
$placeholder-color: #313b42 !default;

// Headers
$h1-font-size: 42px !default;
$h2-font-size: 28px !default;
$h3-font-size: 24px  !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;
$headings-weight: 500 !default;


$animation-speed: 0.3s;

// grid 
$usegrid: true;
$gutter-width: 30px;  // gutter for desktop greater than 768
$gutter-width-mobile: 30px;  // gutter for mobiles
$container-width: 930px;
$grid-count: 12;