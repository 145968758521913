// ---------------------------------------------------------===
// Forms
// ---------------------------------------------------------===

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date'],
textarea,
select {
  -webkit-appearance: none;
  -webkit-border-radius: $form-border-radius;;
  border-radius: $form-border-radius;
  box-sizing: border-box;
  border: 1px solid $form-element-border-color;
  padding: $form-element-padding;
  border-radius: $form-border-radius;
  @include font(14px, 1.29);
  color: #313b42;
  background: $white;
  @include animate(border);

  &:focus,
  &:hover {
    border-color: $form-element-focus-border-color;
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

select {
  -webkit-border-radius: $form-border-radius;
}

textarea {
  resize: vertical;
  vertical-align: top;
  height: auto;
  min-height: 100px;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: $form-border-radius;;
  cursor: pointer;
  color: $btn-color;
  background: $btn-background;
  padding: $btn-padding;
  @include font(14px, 1.29, 500);
  border: 1px solid $btn-background;
  border-radius: $btn-border-radius;
  @include animate(background color border-color);
  @extend %text-ellipsis;

  &:hover,
  &:focus {
    background: $white;
    color: #313b42;
    border-color: $form-element-border-color;
  }
}