// ---------------------------------------------------------===
// Common styles
// ---------------------------------------------------------===

.intro-block {
  @include padding(7px null 33px);
  position: relative;

  @include media('>=tablet') {
    @include padding(0 null 77px);
  }

  @include media('>=desktop') {
    padding-bottom: 127px;
  }

  .sub-container {

    @include media('>=desktop') {
      max-width: 790px;
    }
  }

  .sub-sub-container {

    @include media('>=tablet') {
      @include padding(null 13px null 13px);
      letter-spacing: -0.3px;
    }

    @include media('>=desktop') {
      padding-right: 43px;
    }
  }

  h1 {
    margin-bottom: 24px;
    letter-spacing: -0.36px;

    @include media('>=tablet') {
      margin-bottom: 27px;
    }
  }

  p {
    margin-bottom: 22px;

    @include media('>=tablet') {
      margin-bottom: 39px;
    }

    @include media('>=desktop') {
      margin-bottom: 50px;
    }
  }

  .down-arrow {
    text-decoration: none;
    color: #4459fb;
    @include animate(color);

    &:hover,
    &:focus {
      color: darken(#4459fb, 15%);
    }
  }

  .icon-down-arrow {
    display: none;
    position: absolute;
    right: 70px;
    font-size: 153px;
    bottom: 136px;

    @include media(">=desktop") {
      display: inline-block;
    }
  }
}

.request-access-form {
  @extend %clearfix;

  .form-elem {
    padding-bottom: 9px;

    @include media('>=tablet') {
      float: left;
      padding-right: 11px;
    }

    &:first-child {

      @include media('>=tablet') {
        width: 293px;
      }
    }

    &:last-child {

      @include media('>=tablet') {
        width: 197px;
      }
    }
  }

  input[type="email"],
  input[type="submit"] {
    width: 100%;
    display: block;
  }
}

.post {

  @include media('>=tablet') {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .media-area {
    position: relative;
  }

  .media01,
  .media02,
  .media03,
  .media04 {

    img {
      @extend %img-reset;
    }
  }

  .txt-area {

    >*:last-child {
      margin-bottom: 0;
    }

    h2 {
      letter-spacing: -0.24px;

      @include media('>=tablet') {
        letter-spacing: -0.34px;
      }
    }

    p {
      letter-spacing: -0.27px;
      margin-bottom: 1.9em;

      @include media('>=tablet') {
        letter-spacing: -0.3px;
      }
    }
  }
}

.post.style01 {
  padding-bottom: 43px;

  @include media('>=tablet') {
    padding-bottom: 80px;
  }

  @include media('>=desktop') {
    padding-bottom: 120px;
  }

  @include media('>=widescreen') {
    padding-bottom: 174px;
  }

  .media-area {

    @include media('>=tablet') {
      width: 49.9vw;
    }
  }

  .media01 {
    width: 75.5vw;

    @include media('>=tablet') {
      width: 41.3889vw;
    }
  }

  .media02 {
    border-radius: 0.21vw;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(163, 163, 163, 0.5);
    width: 39.0625vw;
    position: absolute;
    top: 50%;
    transform: translateY(-55.2%);
    right: 8.9vw;

    @include media('>=tablet') {
      box-shadow: 0 7px 28px rgba(163, 163, 163, 0.5);
      width: 16.4583vw;
      right: 1.9vw;
      transform: translateY(-56%);
    }
  }

  .txt-area {
    padding: 32px 28px 0;

    @include media('>=tablet') {
      width: 48.9vw;
      padding: 0 28px 0 5vw;
    }

    @include media('>=1198px') {
      padding: 0 12vw 2.6vw 8vw;
    }
  }

  h2 {
    margin-bottom: 23px;

    @include media('>=tablet') {
      margin-bottom: 36px;
    }
  }
}

.post.style02 {
  padding-bottom: 40px;

  @include media('>=tablet') {
    flex-direction: row-reverse;
    padding-bottom: 80px;
  }

  @include media('>=desktop') {
    padding-bottom: 120px;
  }

  @include media('>=widescreen') {
    padding-bottom: 157px;
  }

  .media-area {

    @include media('>=tablet') {
      width: 46.9vw;
    }
  }

  .holder {
    position: relative;
    margin-top: -82vw;

    @include media(">=tablet") {
      margin-top: -37.3vw;
    }
  } 

  .media01 {
    width: 78.13vw;
    margin-left: auto;

    @include media('>=tablet') {
      width: 41.04vw;
    }
  }

  .media02 {
    border-radius: 0.31vw;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09), 0 1px 14px rgba(0, 0, 0, 0.15);
    width: 57.81vw;
    position: relative;
    left: 9.38vw;
    margin-bottom: 2.2vw;

    @include media('>=tablet') {
      border-radius: 0.21vw;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09), 0 2px 30px rgba(0, 0, 0, 0.15);
      width: 26.46vw;
      left: 0;
      margin-bottom: 1.1vw;
    }
  }

  .txt-area {
    padding: 28px 28px 0;

    @include media('>=tablet') {
      width: 48.9vw;
      padding: 0 28px 0 5vw;
    }

    @include media('>=1198px') {
      padding: 0 8vw 12.3vw 10.3vw;
    }

    p {
      margin-bottom: 1.6em;

      @include media(">=tablet") {
        margin-bottom: 3em;
      }
    }
  }

  h2 {
    margin-bottom: 23px;

    @include media('>=tablet') {
      margin-bottom: 36px;
    }
  }
}

.post.style04 {
  padding-bottom: 52px;

  @include media('>=tablet') {
    padding-bottom: 80px;
  }

  @include media('>=desktop') {
    padding-bottom: 120px;
  }

  @include media('>=widescreen') {
    padding-bottom: 188px;
  }

  .media-area {

    @include media('>=tablet') {
      width: 49.38vw;
    }
  }

  .media01 {
    border-radius: 0.63vw;
    overflow: hidden;
    width: 66.56vw;
    position: relative;
    left: 9.38vw;
    margin-bottom: 5.43vw;
    z-index: 1;

    @include media('>=tablet') {
      border-radius: 0.28vw;
      left: 8.4vw;
      width: 26.25vw;
      margin-bottom: 2.13vw;
    }
  }

  .media02 {
    border: 0.31vw solid #ececec;
    border-radius: 0.63vw;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.13), 0 1px 0 rgba(228, 228, 228, 0.5);
    width: 67.19vw;
    position: relative;
    left: 8.38vw;
    z-index: 3;

    @include media('>=tablet') {
      border-width: 0.07vw;
      left: 8.1vw;
      width: 26.46vw;
      border-radius: 3px;
      box-shadow: 0 0 27px rgba(0, 0, 0, 0.13), 0 1px 0 rgba(228, 228, 228, 0.5);
    }
  }

  .media03 {
    background: $white;
    border-radius: 0.31vw;
    overflow: hidden;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13), 0 1px 0 rgba(228, 228, 228, 0.5);
    width: 50.31vw;
    position: absolute;
    top: 23.7vw;
    right: 0;
    z-index: 2;

    @include media('>=tablet') {
      width: 25.07vw;
      border-radius: 0.14vw;
      box-shadow: 0 0 18px rgba(0, 0, 0, 0.13), 0 1px 0 rgba(228, 228, 228, 0.5);
      top: 9.3vw;
    }
  }

  .txt-area {
    padding: 27px 28px 0;

    @include media('>=tablet') {
      width: 48.9vw;
      padding: 0 28px 0 5vw;
    }

    @include media('>=1198px') {
      padding: 0 10.2vw 1.7vw 8vw;
    }
  }

  h2 {
    margin-bottom: 23px;

    @include media('>=tablet') {
      margin-bottom: 44px;
    }
  }
}

.post.style06 {
  padding-bottom: 53px;

  @include media('>=tablet') {
    padding-bottom: 80px;
  }

  @include media('>=desktop') {
    padding-bottom: 120px;
  }

  @include media('>=widescreen') {
    padding-bottom: 186px;
  }

  .media-area {

    @include media('>=tablet') {
      width: 42.92vw;
    }
  }

  .media01 {
    width: 80.2vw;
    position: relative;
    left: 9.69vw;

    @include media('>=tablet') {
      left: 15.14vw;
      width: 27.8vw;
    }
  }

  .txt-area {
    padding: 45px 28px 0;

    @include media('>=tablet') {
      width: 48.9vw;
      padding: 0 28px 0 5vw;
    }

    @include media('>=1198px') {
      padding: 0 10.2vw 0 8vw;
    }
  }

  h2 {
    margin-bottom: 21px;

    @include media('>=tablet') {
      margin-bottom: 44px;
    }
  }
}

.txt-block {
  padding-bottom: 52px;

  @include media('>=desktop') {
    margin-bottom: 93px;
  }

  .sub-container {

    @include media('>=desktop') {
      max-width: 827px;
    }

    >*:last-child {
      margin-bottom: 0;
    }
  }

  .h1 {
    display: block;
    letter-spacing: -0.36px;

    @include media('>=desktop') {
      letter-spacing: -0.67px;
    }
  }
}

.partners-block {
  padding-bottom: 42px;

  @include media('>=tablet') {
    padding-bottom: 100px;
  }

  @include media('>=desktop') {
    padding-bottom: 120px;
  }

  @include media('>=widescreen') {
    padding-bottom: 150px;
  }

  header {
    text-align: center;
    position: relative;
    padding-top: 46px;

    @include media('>=tablet') {
      padding-top: 40px;
    }

    @include media('>=desktop') {
      padding-top: 60px;
    }

    @include media('>=widescreen') {
      padding-top: 80px;
    }

    &:before {
      content: '';
      @include size(50px, 4px);
      background: #2c2c2c;
      @include vertical-align-x;
      top: 0;
    }
  }

  h2 {
    letter-spacing: -0.24px;
    max-width: 880px;
    margin: 0 auto 62px;

    @include media('>=tablet') {
      margin-bottom: 62px;
    }

    @include media('>=desktop') {
      letter-spacing: -0.34px;
      margin-bottom: 75px;
    }

    @include media('>=widescreen') {
      margin-bottom: 90px;
    }
  }
}

.partners-list {
  @extend %listreset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-top: -25px;

  li {
    width: 132px;
    padding: 25px 10px 0;

    @include media('>=tablet') {
      width: auto;
      min-width: 122px;
    }

    &:first-child {
      width: 100%;

      @include media('>=tablet') {
        width: auto;
      }
    }
  }

  img {
    vertical-align: top;
  }

  .txt {
    display: block;
    letter-spacing: -0.19px;
    @include font(11px, 1.82, 500);
    color: #2c2c2c;
    padding-top: 17px;

    @include media('>=tablet') {
      letter-spacing: -0.2px;
      padding-top: 20px;
    }
  }
}

.list {
  @extend %listreset;
  font-size: 11px;
  column-count: 2;
  column-gap: 28px;
  letter-spacing: -0.18px;

  @include media(">=tablet") {
    font-size: 16px;
    column-gap: 10px;
    letter-spacing: -0.27px;
  }

  @include media(">=desktop") {
    column-gap: 21px;
  }

  li {
    position: relative;
    padding-bottom: 3px;
    padding-left: 11px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    display:table;

    @include media(">=tablet") {
      padding-left: 17px;
      padding-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 1px;
      background: #2c2c2c;
      @include size(7px, 1px);

      @include media(">=tablet") {
        top: 14px;
        left: 3px;
        width: 10px;
      }
    }
  }
}

.feature-post {
  padding-bottom: 44px;
  position: relative;

  @include media(">=tablet") {
    @include padding(null 2.3vw 20vw);
  }

  .media-area {
    position: relative;
  }

  .media01,
  .media02 {
    position: relative;
    img {
      @extend %img-reset;
    }

    .bullet {
      position: absolute;
      @include size(3.44vw);
      top: 19%;
      left: -3px;

      @include media(">=tablet") {
        @include size(1.25vw)
        left: -4px;
      }
    }
  }

  .media02 {
    width: 75.63vw;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.13), 0 1px 0 rgba(228, 228, 228, 0.5);    
    margin: 0 auto 3.3vw;

    img {
      border-radius: 0.31vw;

      @include media(">=tablet") {
        border-radius: 0.26vw;
      }
    }

    @include media(">=tablet") {
      width: 30.49vw;
      box-shadow: 0 0 27px rgba(0, 0, 0, 0.13), 0 2px 0 rgba(228, 228, 228, 0.5);
      margin-bottom: 1.1vw;
    }
  }

  .holder {
    margin-top: -104vw;

    @include media(">=tablet") {
      position: relative;
      left: 0;
      right: 0;
      margin-top: -26.4vw;
    }

    @include media(">=widescreen") {
      margin-top: -33.4vw;
    }
  }

  .txt-area {
    padding: 13px 28px 0;

    @include media(">=tablet") {
      padding: 26px 0 0 3vw;
      position: absolute;
      top: 0;
      z-index: 2;
      width: 60vw;
      max-height: 34vw;
    }

    @include media(">=widescreen") {
      padding: 70px 0 0 8vw;
      max-height: 28.6vw;
    }

    @include media(">=1398px") {
      padding-top: 117px;
      width: 44vw;
    }
  }

  h2 {
    margin-bottom: 19px;

    @include media(">=tablet") {
      margin-bottom: 28px;
      letter-spacing: -0.34px;
    }
  }

  p {
    margin-bottom: 0.6em;

    @include media('>=tablet') {
      letter-spacing: -0.3px;
    }
  }

  .text-block {
    padding: 13px 28px 0;
    color: #7b7b7b;
    font-size: 12px;
    line-height: 19.5px;
    letter-spacing: 0.07px;

    @include media(">=tablet") {
      width: 27vw;
      padding: 26px 0 0 3vw;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.09px;
      margin-top: -24.3vw;
      letter-spacing: 0.09px;
      position: relative;
      z-index: 3;
    }

    @include media(">=desktop") {
      margin-top: -18.3vw;
    }

    @include media(">=widescreen") {
      padding: 46px 0 0 8vw;
    }
  }
}

.post.style05 {
  padding-bottom: 38px;

  @include media('>=tablet') {
    flex-direction: row-reverse;
    padding-bottom: 80px;
  }

  @include media('>=desktop') {
    padding-bottom: 120px;
  }

  @include media('>=widescreen') {
    padding-bottom: 188px;
  }

  .media-area {

    @include media('>=tablet') {
      width: 48.1vw;
    }
  }

  .holder {
    position: relative;
    @extend %clearfix;
    margin-top: -9.1vw;
    margin-left: -0.81vw;

    @include media(">=tablet") {
      margin-top: -4vw;
      margin-left: 0;
    }
  } 

  .media01 {
    width: 76.25vw;
    margin-left: auto;

    @include media('>=tablet') {
      width: 41.04vw;
    }
  }

  .media02 {
    border-radius: 0.31vw;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05), 0 1px 0 #eaeaea;
    width: 26.25vw;
    position: relative;
    left: 9.38vw;
    margin-right: 2.2vw;
    float: left;

    @include media('>=tablet') {
      border-radius: 0.21vw;
      box-shadow: 0 0 29px rgba(0, 0, 0, 0.05), 0 2px 0 #eaeaea;
      width: 12.57vw;
      left: 0;
      margin-right: 1.1vw;
    }
  }

  .text-block {
    color: #7b7b7b;
    font-size: 12px;
    line-height: 1.63;
    letter-spacing: 0.07px;
    padding: 21px 28px 12px;
    
    @include media(">=tablet") {
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: -0.27px;
      padding: 45px 7.1vw 0;
    }
  }

  .txt-area {
    @include padding(null 28px);

    @include media('>=tablet') {
      width: 48.9vw;
      padding: 0 28px 0 5vw;
    }

    @include media('>=1198px') {
      padding: 0 8.1vw 2vw 10.3vw;
      margin-bottom: -14px;
    }

    p {
      margin-bottom: 1.5em;

      @include media(">=desktop") {
        margin-bottom: 2.5em;
      }
    }
  }

  h2 {
    margin-bottom: 20px;

    @include media('>=desktop') {
      margin-bottom: 44px;
    }
  }

  .title {
    font-size: 13px;
    line-height: 1.66;
    font-weight: 500;
    display: block;
    margin-bottom: 0.6em;

    @include media('>=tablet') {
      font-size: 18px;
      line-height: 1.78;
      margin-bottom: 0.6em;
    }
  }

  .list {
    column-count: 1;
    column-gap: 0;

    li {
      display: block;
      padding-bottom: 0;
    }
  }
}

.columns {
  letter-spacing: -1em;
  margin: 0 -8px;

  @include media(">=tablet") {
    margin: 0 -22px;
  }

  .col {
    letter-spacing: 0;
    width: 50%;
    padding: 0 8px 27px;
    display: inline-block;
    vertical-align: top;

    @include media(">=desktop") {
      padding: 0 22px 32px;
    }
  }
}