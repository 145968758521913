// ---------------------------------------------------------===
// General resets
// ---------------------------------------------------------===

html,
body { height: 100%; }
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
  color: $base-text-color;
  background: $base-background-color;
  font: #{$font-size-base}/#{$line-height-base} $base-font-family;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media('>=desktop') {
    @include font(18px, 1.78);
  }
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
}

// google map fix
.gm-style img {max-width: none}

b, strong {
  font-weight: 500;
}

// hide tel links on desktop
@media only screen and (min-width:1025px) {
  a[href^=tel],a[href^=skype], a[href^=sms] {
    cursor:default;
    pointer-events:none;
  }
}

// wrapper
#wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.block-elem {
  width: 100% !important;
}

.container {
  max-width: 1198px;
  @include margin(null auto);
  @include padding(null 28px);
}

.for-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}