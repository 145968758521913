// ---------------------------------------------------------===
// Header styles
// ---------------------------------------------------------===

#header {
  @include padding(33px null);

  @include media('>=tablet') {
    @include padding(51px null 50px);
  }
}

.logo {
  @include size(125px, auto);

  img {
    @extend %img-reset;
  }
}