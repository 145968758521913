// ---------------------------------------------------------===
// Footer styles
// ---------------------------------------------------------===

#footer {
  background: #2c2c2c;
  color: $white;

  a {
    color: inherit;
  }

  .request-access-form {
    padding-bottom: 53px;

    @include media('>=desktop') {
      padding-bottom: 76px;
    }
  }

  .social-networks {
    @include margin(null -3px);

    @include media('>=tablet') {
      @include margin(null -10px);
    }
  }

  .f-txt-block-01 {
    padding-top: 45px;

    @include media('>=desktop') {
      padding-top: 10px;
    }

    @include media('>=widescreen') {
      padding-top: 26px;
    }
  }

  .f-nav a:hover,
  .f-nav a:focus {
    color: $white;
  }
}

.f-top-container {
  @include padding(108px null 46px);

  @include media('>=tablet') {
    @include padding(50px null 57px);
  }

  @include media('>=desktop') {
    @include padding(100px null 112px);
  }

  @include media('>=widescreen') {
    @include padding(155px null 167px);
  }
}

.f-txt-block {
  letter-spacing: -0.3px;

  @include media('>=tablet') {
    max-width: 500px;
  }

  h2 {
    @include font(59px, 1.17);
    margin-bottom: 31px;
    letter-spacing: -0.51px;

    @include media('>=desktop') {
      @include font(78px, 1.18);
      letter-spacing: -0.67px;
      margin-bottom: 41px;
    }
  }

  p {
    margin-bottom: 43px;

    @include media('>=tablet') {
      margin-bottom: 62px;
    }
  }
}

.f-row {

  @include media('>=desktop') {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .f-col {

    @include media('>=desktop') {
      flex: 1;
    }

    &:first-child {

      @include media('>=desktop') {
        flex: 0 0 500px;
        max-width: 500px;
      }
    }

    &:last-child {

      // @include media('>=desktop') {
      //   flex: 0 0 300px;
      //   max-width: 300px;
      // }

      @include media('>=desktop') {
        flex: 0 0 385px;
        max-width: 385px;
      }
    }
  }
}

.social-networks {
  @extend %listreset;
  @include dib-reset;
  font-size: 23px;

  li {
    padding: 0 12px 10px;
  }

  a {
    text-decoration: none;
    opacity: 0.2;
    @include animate(opacity);

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  [class^="icon-"] {
    display: block;
  }

  .icon-twitter {
    font-size: 86.96%;
    margin-top: 2px;
  }

  .icon-pinterest {
    margin-top: 2px;
  }
}

.f-txt-block-01 {
  @include font(18px, 1.78);

  @include media('>=tablet','<desktop') {
    @include font(16px, 1.63);
  }

  @include media('>=tablet') {
    padding-right: 15px;
  }

  .ico {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 23px;
    font-size: 25px;

    [class^="icon-"] {
      display: block;
    }
  }

  p {
    letter-spacing: -0.3px;
  }
}

.f-btm-container {
  position: relative;
  color: #808080;
  @include font(12px, 1.67);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 28px;
    right: 28px;
    height: 1px;
    background: #4b4b4b;

    @include media('>=tablet') {
      left: 31px;
      right: 31px;
    }
  }

  .container {
    @include padding(36px null 65px);

    @include media('>=tablet') {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      @include padding(32px null 51px);
    }

    .copyright,
    .f-nav {

      @include media('>=tablet') {
        flex: 1;
      }
    }
  }
}

.copyright {
  display: block;
  padding-bottom: 12px;

  @include media('>=tablet') {
    @include padding(null 15px 0 null);
  }
}

.f-nav {
  @extend %listreset;
  @include dib-reset;
  @include margin(null -8px);

  @include media('>=tablet') {
    padding-left: 15px;
    text-align: right;
    @include margin(null -7px);
  }

  li {
    @include padding(null 8px);

    @include media('>=tablet') {
      @include padding(null 7px);
    }
  }

  a {
    text-decoration: none;
    @include animate(color);

    &:hover,
    &:focus {
      color: $white;
    }
  }
}